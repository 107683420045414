import {GlobalService} from '../services/global.service';
import {NewProjectDialogComponent} from './new-project-dialog/new-project-dialog';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../services/toasts.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ProjectsService} from '../services/projects.service';
import {GroupsService} from '../services/groups.service';
import {Subscription} from 'rxjs';
import {EditProjectDialogComponent} from './edit-project-dialog/edit-project-dialog';
import {AuthService} from '../auth/auth.service';
import {ExportDataDialogComponent} from './export-data-dialog/export-data-dialog';


@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
    projectsSubscription: Subscription;
    tasksSubscription: Subscription;
    projects = [];
    templates = [];
    newTemplates = [];
    categories = [];
    newCategories = [];
    taskNames = [];
    loading = true;
    membershipsLoaded = false;
    viewArchived = false;
    viewAssigned = false;
    viewOverdue = false;
    selectedTemplate = null;
    selectedLocation = null;
    selectedTaskName = null;
    selectedAssignedGroup = null;
    selectedAge = null;
    selectedDueDate = null;
    viewAll = false;
    // selectedDate = new FormControl(moment());

    membershipsLoadedSubscription: Subscription;
    templatesSubscription: Subscription;
    newTemplatesSubscription: Subscription;

    projectsDataSource = new MatTableDataSource(null);
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    projectsColumns: string[] = [
        'name',
        'location',
        'template',
        'due_date',
        'status',
        'edit'
    ];

    ages = [
        'Last week',
        'Last month',
    ];

    constructor(private router: Router,
                public globalService: GlobalService,
                private groupsService: GroupsService,
                public projectsService: ProjectsService,
                public dialog: MatDialog,
                private http: HttpClient,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
            if (this.membershipsLoaded) {
                this.handleRouteAuthentication();
            }
        });
        this.templatesSubscription = this.projectsService.templates$.subscribe(templates => {
            this.templates = templates;
            const categories = [];
            for (const template of templates) {
                categories.push(template['category']);
            }
            this.categories = categories.filter(this.onlyUnique);
            // TODO add dropdown to select category, which filters list of available templates in corresponding dropdown
        });
        this.newTemplatesSubscription = this.projectsService.newTemplates$.subscribe(templates => {
            this.newTemplates = templates;
            const categories = [];
            for (const template of templates) {
                categories.push(template['category']);
            }
            this.newCategories = categories.filter(this.onlyUnique);
            // TODO add dropdown to select category, which filters list of available templates in corresponding dropdown
        });
        this.loadProjects();
        this.loadSearchSettings();
    }

    handleRouteAuthentication() {
        const allowedUsers = [
            'ngarcia@claritychi.com',
            'dgratzke@claritychi.com',
            'tjavidan@claritychi.com',
            'drcolombatto@claritychi.com',
            'drbobb@claritychi.com',
            'agrochal@claritychi.com',
            'mnunez@claritychi.com',
            'jlozada@claritychi.com',
            'shasan@claritychi.com',
            'drnoreika@claritychi.com',
            'cnoreika@claritychi.com',
            'ledwards@claritychi.com',
            'dredwards@claritychi.com',
        ];

        if (allowedUsers.includes(this.authService.user.email)) {
            return;
        }

        for (const group of this.groupsService.currentUsersGroups) {
            if (group.startsWith('projectmanagement')) {
                return;
            }
        }

        this.router.navigate(['help']);
    }

    loadSearchSettings() {
        const searchSettings = this.globalService.searchSettings;
        this.viewArchived = searchSettings.viewArchived;
        this.viewAssigned = searchSettings.viewAssigned;
        this.viewOverdue = searchSettings.viewOverdue;
        this.viewAll = searchSettings.viewAll;
        this.selectedTemplate = searchSettings.selectedTemplate;
        this.selectedLocation = searchSettings.selectedLocation;
        this.selectedTaskName = searchSettings.selectedTaskName;
        this.selectedAssignedGroup = searchSettings.selectedAssignedGroup;
        this.selectedAge = searchSettings.selectedAge;
        this.updateView();
    }

    saveSearchSettings() {
        const searchSettings = {
            viewArchived: this.viewArchived,
            viewAssigned: this.viewAssigned,
            viewOverdue: this.viewOverdue,
            viewAll: this.viewAll,
            selectedTemplate: this.selectedTemplate,
            selectedLocation: this.selectedLocation,
            selectedTaskName: this.selectedTaskName,
            selectedAssignedGroup: this.selectedAssignedGroup,
            selectedAge: this.selectedAge,
        };
        this.globalService.searchSettings = searchSettings;
    }

    deleteProject(projectPath) {
        this.projectsService.deleteAtPath(projectPath);
    }

    toggleArchiveProject(projectId, archived) {
        console.log(projectId);
        try {
            const itemDoc = this.afs.doc<any>('projects/' + projectId);
            itemDoc.update({'archived': !archived});
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    ngAfterViewInit() {
        this.projectsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'due_date': return data['due_date'];
                case 'created': return data['created'];
                case 'name': return ((data['first_name'] + ' ' + data['last_name']).toLocaleLowerCase());
                default: return data[sortHeaderId].toLocaleLowerCase();
            }
        };
        this.projectsDataSource.paginator = this.paginator;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    loadProjects() {
        this.loading = true;
        this.projectsDataSource.data = [];
        const yearsAgo  = this.viewAll ? 100 : 1;
        const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - yearsAgo));
        const projectsCollection = this.afs.collection<any>('projects', ref =>
            ref.where('created', '>', lastYear).orderBy('created', 'desc'));
        if (!this.viewArchived) {
            projectsCollection.ref.where('archived', '==', false);
        }
        this.projectsSubscription = projectsCollection.valueChanges({idField: 'id'}).subscribe((projects) => {
            projects = projects.sort((a, b) => {
                return a['due_date'].toDate() - b['due_date'].toDate();
            });
            for (const project of projects) {
                const tasksCollection = projectsCollection.doc(project['id']).collection('tasks', ref => ref.orderBy('order'));
                this.tasksSubscription = tasksCollection.valueChanges({idField: 'id'}).subscribe((projectsTasks) => {
                    project['tasks'] = projectsTasks;

                    this.updateIncompleteTasks();

                    const unfinished_tasks_assigned_to_this_user = projectsTasks.filter(s => this.groupsService.currentUsersGroups.includes(s.assigned_to) && !s.completed_by).length;
                    if (unfinished_tasks_assigned_to_this_user) {
                        project['unfinished_tasks_assigned_to_this_user'] = true;
                    }

                    const overdue_tasks = projectsTasks.filter(s => moment(s.due_date.toDate()).isBefore(moment())).length;
                    if (overdue_tasks) {
                        project['overdue_tasks'] = true;
                    }

                    this.updateView();
                    this.projectsDataSource.sort = this.sort;
                }, error => {
                    this.toastsService.showSnackBar(error, 'error', 5000);
                });
            }
            this.projects = projects;
            this.projectsDataSource.data = projects;
            this.updateView();
            this.loading = false;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    applyFilter(filterValue: string) {
        this.projectsDataSource.filter = filterValue.trim().toLowerCase();
    }

    newProject(category) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            category: category
        };

        const dialogRef = this.dialog.open(NewProjectDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async projectId => {
            if (projectId) {
                console.log(projectId);
                this.navigateToProject(projectId);
            }
        });
    }

    toggleViewAssigned() {
        this.viewAssigned = !this.viewAssigned;
        this.updateView();
    }

    toggleViewOverdue() {
        this.viewOverdue = !this.viewOverdue;
        this.updateView();
    }

    toggleViewArchived() {
        this.viewArchived = !this.viewArchived;
        this.loadProjects();
    }

    toggleViewAll() {
        this.viewAll = !this.viewAll;
        this.loadProjects();
    }

    selectTemplate(template_name: string) {
        this.selectedTemplate = template_name;
        this.updateView();
    }

    selectTaskName(task_name: string) {
        this.selectedTaskName = task_name;
        this.updateView();
    }

    selectAssignedGroup(group: string) {
        this.selectedAssignedGroup = group;
        this.selectedTaskName = null;
        this.updateIncompleteTasks();
        this.updateView();
    }

    updateIncompleteTasks() {
        const projects = this.projects;
        let allTasks = [];
        for (const project of projects) {
            if (project['tasks'] && project['tasks'].length) {
                const incompleteTasks = project['tasks'].filter(task => {
                    if (this.selectedAssignedGroup) {
                        return !task['completed'] && task['assigned_to'] === this.selectedAssignedGroup;
                    }
                    return !task['completed'];
                });
                const taskNames = incompleteTasks.map(task => task['name']);
                allTasks = allTasks.concat(taskNames);
                allTasks = allTasks.filter(this.onlyUnique);
                allTasks.sort();
            }
        }
        this.taskNames = allTasks;
    }

    selectAge(age: string) {
        this.selectedAge = age;
        this.updateView();
    }

    selectLocation(location: string) {
        this.selectedLocation = location;
        this.updateView();
    }

    selectDueDate(event) {
        this.selectedDueDate = event.value;
        this.updateView();
    }

    updateView() {
        let filteredProjects = this.projects;

        if (this.viewAssigned) {
            filteredProjects = filteredProjects.filter(p => p['unfinished_tasks_assigned_to_this_user'] === true);
        }

        if (this.viewOverdue) {
            filteredProjects = filteredProjects.filter(p => p['overdue_tasks'] === true);
        }

        if (!this.viewArchived) {
            filteredProjects = filteredProjects.filter(p => p['archived'] === false);
        }

        if (this.selectedTemplate) {
            filteredProjects = filteredProjects.filter(p => p['template'] === this.selectedTemplate);
        }

        if (this.selectedLocation) {
            filteredProjects = filteredProjects.filter(p => p['location'] === this.selectedLocation);
        }

        if (this.selectedDueDate) {
            const date = moment(this.selectedDueDate);
            filteredProjects = filteredProjects.filter(x => {
                const itemDate = moment(x['due_date'].toDate());
                return itemDate.isSame(date, 'day');
            });
        }

        if (this.selectedTaskName && this.selectedAssignedGroup) {
            filteredProjects = filteredProjects.filter(p => {
                if (p['tasks'] && p['tasks'].length) {
                    for (const task of p['tasks']) {
                        if (task.name === this.selectedTaskName && task.assigned_to === this.selectedAssignedGroup && !task.completed) {
                            return true;
                        }
                    }
                }
                return false;
            });
        } else if (this.selectedTaskName) {
            filteredProjects = filteredProjects.filter(p => {
                if (p['tasks'] && p['tasks'].length) {
                    for (const task of p['tasks']) {
                        if (task.name === this.selectedTaskName && !task.completed) {
                            return true;
                        }
                    }
                }
                return false;
            });
        } else if (this.selectedAssignedGroup) {
            filteredProjects = filteredProjects.filter(p => {
                if (p['tasks'] && p['tasks'].length) {
                    for (const task of p['tasks']) {
                        if (task.assigned_to === this.selectedAssignedGroup && !task.completed) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }

        if (this.selectedAge) {
            let cutoff;
            if (this.selectedAge === this.ages[0]) {
                cutoff = moment().subtract(7, 'days');
            } else if (this.selectedAge === this.ages[1]) {
                cutoff = moment().subtract(30, 'days');
            }
            filteredProjects = filteredProjects.filter(p => moment(p['created'].toDate()).isAfter(cutoff));
        }

        this.projectsDataSource.data = filteredProjects;
    }

    async navigateToProject(projectId) {
        await this.router.navigate(['projects', projectId]);
    }

    editProject(project) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            project: project
        };

        const dialogRef = this.dialog.open(EditProjectDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async projectId => {
            if (projectId) {
                console.log(projectId);
            }
        });
    }

    exportData() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            url: 'export-projects-data',
            filename: 'projects_data.csv'
        };

        const dialogRef = this.dialog.open(ExportDataDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    ngOnDestroy() {
        this.membershipsLoadedSubscription.unsubscribe();
        this.templatesSubscription.unsubscribe();
        this.newTemplatesSubscription.unsubscribe();
        this.projectsSubscription.unsubscribe();
        this.tasksSubscription.unsubscribe();
        this.saveSearchSettings();
    }

}
